import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from '../data/data.service';

import { AddressResponse } from '../../interfaces/address-response';
import { AddressInternationalResponse } from '../../interfaces/address-international-response';



@Injectable({
  providedIn: 'root'
})
export class VerifyUsService {

  verifyUSURL = 'https://us-street.api.smartystreets.com/street-address?';
  verifyINTURL = 'https://international-street.api.smartystreets.com/verify?';
  isAddressValidation = null;

  constructor(private http: HttpClient, private dataService: DataService) { }

  verifyInternationalAddress(addressToBeVerified: any): Observable<AddressInternationalResponse[]> {
    const websiteKey = this.dataService.get().propertyConfig.addressValidationAuthKey;
    const url = `${this.verifyINTURL}auth-id=${websiteKey}&${this.serialize(addressToBeVerified)}`;
    const usAddressUrl = url;
    return this.http.get<AddressInternationalResponse[]>(usAddressUrl).pipe(
     tap(json => json));
  }

  verifyAddress(addressToBeVerified: any): Observable<AddressResponse[]> {
    const websiteKey = this.dataService.get().propertyConfig.addressValidationAuthKey;
    const url = `${this.verifyUSURL}auth-id=${websiteKey}&${this.serialize(addressToBeVerified)}`;
    const usAddressUrl = url + '&candidates=2&geocode=true&match=invalid';
    // const addressQry = 'https://us-street.api.smartystreets.com/street-address?auth-id=31008886784763106&city=Herndon&state=VA&street=2300%20Dulles%20Corner%20Ln&country=US&candidates=2&geocode=true&match=strict';
    return this.http.get<AddressResponse[]>(usAddressUrl).pipe(
      tap(json => json));
  }

  verifyInternationalAddressVerificationToggle(): boolean{
    const internationalAddressVerificationToggle = this.dataService.get().propertyConfig.internationalAddressVerificationToggle;
    return this.isAddressValidation === "Y" && internationalAddressVerificationToggle;
  }

  serialize(obj, prefix?): string {
    const str = [];
    let p;

    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        if (v) {
          str.push(v !== null && typeof v === 'object' ? this.serialize(v, k) : k + '=' + encodeURIComponent(v));
        }
      }
    }

    return str.join('&');
  }

}

